export default {
  overview: {
    title: 'Online-Anmeldung der {{organization}}',
    description:
      'Herzlich Willkommen zur Online-Anmeldung der {{organization}}. Hier siehst du alle aktuell offenen Anmeldungen.',
    apply: 'Jetzt anmelden',
  },
  buttons: {
    next: 'Weiter',
    previous: 'Zurück',
    save: 'Speichern',
  },
  addCustodian: {
    title: 'Sorgeberechtigten hinzufügen',
    description: 'Füge einen Sorgeberechtigten hinzu, um Kinder anzumelden',
  },
  addChild: {
    title: 'Kind hinzufügen',
    description: 'Füge ein Kind zu deiner Anmeldung an',
  },
  custodian: {
    addAnother: 'Hinzufügen',
    empty: {
      title: 'Keine Sorgeberechtigten hinzugefügt',
      description: 'Zu deiner Anmeldung wurden noch keine Sorgeberechtigten hinzugefügt.',
      action: 'Sorgeberechtigten hinzufügen',
    },
  },
  selectCustodians: {
    title: 'Sorgeberechtigte auswählen',
    description: 'Wähle die Sorgeberechtigten aus, die du hinzufügen möchtest.',
  },
  custodians: {
    title: 'Sorgeberechtigte',
    description:
      'Hier siehst du eine Übersicht über alle Sorgeberechtigten, die du hinzugefügt hast.',
  },
  children: {
    title: 'Angemeldete Kinder',
    description: 'Hier siehst du eine Übersicht über alle Kinder, die du angemeldet hast.',
    addAnother: 'Hinzufügen',
    empty: {
      title: 'Keine Kinder angemeldet',
      description: 'Zu deiner Anmeldung wurden noch keine Kinder hinzugefügt.',
      action: 'Kind hinzufügen',
    },
  },
  personType: {
    custodian: 'Sorgeberechtigter',
    child: 'Kind',
  },
  editEntry: {
    title: 'Eintrag bearbeiten',
    description: 'Bearbeite eine bereits angelegte Person',
  },
  summary: {
    link: "Anmeldung abschließen",
    title: 'Zusammenfassung',
    description: 'Hier siehst du eine Übersicht über alle Personen, die du angemeldet hast.',
    actions: {
      continueEditing: 'Weiter bearbeiten',
      submit: 'Anmeldung abschließen',
    },
  },
  success: {
    title: 'Vielen Dank für Ihre Anfrage',
    description:
      'Ihre Anfrage wurde erfolgreich übermittelt. Wir werden uns in Kürze bei Ihnen melden. Ebenfalls haben wir Ihnen eine Bestätigung per E-Mail zugesendet.',
    addAnother: "Weitere Anmeldung hinzufügen",
  },
};
