export default {
  sections: {
    dangerZone: {
      title: 'Gefahrenbereich',
      description:
        'Hier kannst du Aktionen durchführen, die nicht mehr rückgängig gemacht werden können. Sei vorsichtig!',
    },
  },
  discard: {
    title: "Änderungen verwerfen",
    description: "Möchtest du die Änderungen wirklich verwerfen? Alle ungespeicherten Änderungen gehen verloren.",
    action: "Änderungen verwerfen",
  }
};
