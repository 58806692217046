export default {
  imprint: 'Impressum',
  sent: {
    description:
      'Wir haben eine E-Mail mit einem Anmeldungslink an deine E-Mail Adresse {{email}} versendet. Bitte fahre mit diesem Link fort.',
    title: 'Überprüfe dein Postfach',
  },
  social: {
    tk: 'Mit TK-Schulsoftware anmelden',
  },
  form: {
    email: {
      label: 'E-Mail',
      placeholder: 'john@acme.inc',
    },
  },
  action: 'Anmelden',
  title: 'Willkommen zurück',
  description: 'Bitte melde dich mit deiner E-Mail Adresse oder einer anderen Anmeldungsmethode an',
  disclaimer:
    'Mit der Anmeldung stimmen Sie den Nutzungsbedingungen und Datenschutzrichtlinien der Triargos GmbH bereit.',
  notifications: {
    invalidRequest: 'Ungültige Anfrage. Bitte versuchen Sie es erneut. Code: {{code}}',
    invalidCode: 'Ungültiger Code',
    userNotFound: 'Benutzer wurde nicht gefunden',
    userDeactivated: 'Benutzer ist deaktiviert',
    success: 'E-Mail wurde erfolgreich versendet',
  },
  twoFactor: {
    title: 'Zwei-Faktor-Authentifizierung',
    description: 'Bitte geben Sie den Code Ihrer Authentifikator-App ein, um sich anzumelden.',
    input: 'Code',
    actions: {
      submit: 'Anmelden',
    },
  },
};
