export default {
  title: 'Sicherheit',
  description: 'Verwalte Sicherheitsrelevante Einstellungen und Geräte',
  sections: {
    sessions: {
      title: 'Sitzungen',
      name: '{{browser}} auf {{os}}',
      currentSession: 'Aktuelle Sitzung',
      lastSeen: 'Zuletzt gesehen {{ago}}',
    },
    twoFactorAuth: {
      title: 'Zwei-Faktor-Authentifizierung',
      description: 'Schütze deinen Account mit einer zusätzlichen Sicherheitsstufe',
      activate: 'Zwei-Faktor-Authentifizierung aktivieren',
      verify:
        'Bitte geb den Code deiner Authentifikator-App ein, um die Zwei-Faktor-Authentifizierung zu aktivieren.',
    },
  },
  twoFactorAuth: {
    validation: {
      invalidCode: "Ungültiger Code",
    },
    steps: {
      scanCode: {
        title: 'QR Code scannen',
        description:
          'Scanne den QR-Code mit deiner Authentifikator-App oder füge den Code manuell hinzu:',
      },
      verify: {
        title: 'Code eingeben',
        description:
          'Bitte geb den Code deiner Authentifikator-App ein, um die Zwei-Faktor-Authentifizierung zu aktivieren.',
      },
    },
    actions: {
      verify: "Verifizieren",
      copy: "Kopieren",
    }
  },
  revokeSession: {
    title: 'Sitzung beenden?',
    description: 'Möchtest du diese Sitzung wirklich beenden?',
    action: 'Sitzung beenden',
  },
  notifications: {
    revokeSession: {
      success: 'Sitzung wurde erfolgreich beendet.',
      error: 'Sitzung konnte nicht beendet werden. Bitte versuche es erneut',
      notFound: 'Sitzung nicht gefunden',
    },
    copyCode: {
      success: 'Code wurde erfolgreich kopiert',
    }
  },
};
